<template>
  <div>
    <div class="fdBox" v-for="item in firendJson" :id="item.uId">
      <span class="fdInfo1" :style="{ backgroundImage: 'url(' + item.headPic + ')' }"
        ><!--头像--></span
      >
      <span class="fdInfo2">{{ item.nicName }}</span>
      <span class="fdInfo3"
        ><i :class="[item.sex == 1 ? 'man' : 'woman']"></i> {{ item.age }}</span
      >
      <span class="fdInfo4"><!--消息--></span>
      <span class="fdInfo5">{{ item.intro }}</span>
      <span class="fdInfo6">{{ item.date }}</span>
    </div>
    <div class="fixedBar"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      firendJson: [
        {
          headPic: require("@/assets/img/shuaige.png"),
          nicName: "tony · Wang",
          age: "28",
          sex: 1,
          intro:
            "往事飘零，爱恨随意",
          date: "5月20",
          uId: 123,
        },
         {
          headPic: require("@/assets/img/shaofu.jpg"),
          nicName: "心的2分之1",
          age: "28",
          sex: 1,
          intro:
            "最近没喝奶茶，宝宝都不甜了ヾ(o◕∀◕)ﾉヾ",
          date: "5月20",
          uId: 123,
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="less">
.fdBox {
  height: 159px;
  border-bottom: 1px #f3f3f3 solid;
  width: 100%;
  position: relative;
  span {
    position: absolute;
    // border: 1px red dashed;
  }
  .fdInfo1 {
    left: 12px;
    top: 20px;
    height: 56px;
    width: 56px;
    border-radius: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .fdInfo2 {
    left: 74px;
    top: 20px;
    height: 16px;
    line-height: 16px;
    width: 250px;
    color: #222;
    font-size: 15px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .fdInfo3 {
    left: 74px;
    top: 50px;
    height: 12px;
    line-height: 12px;
    width: 56px;
    color: #c72e59;
    font-weight: bold;
    font-size: 12px;
    i{
        display: inline-block;
    }
    .man {
      height: 12px;
      width: 12px;
      background-image: url('../../assets/img/mysex1.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
    }
    .woman {
      height: 12px;
      width: 12px;
 background-image: url('../../assets/img/mysex0.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
    }
  }
  .fdInfo4 {
    right: 20px;
    top: 35px;
    height: 28px;
    width: 28px;
      background-image: url('../../assets/img/msg1.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 28px;
  }
  .fdInfo5 {
    left: 12px;
    top: 90px;
    height: 38px;
    line-height: 20px;

    width: 340px;
    color: #222;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .fdInfo6 {
    left: 12px;
    bottom: 12px;
    height: 12px;
    width: 200px;
    line-height: 12px;
    font-size: 12px;
  }
}
.fixedBar{
    position: fixed;
    right: 20px;
    bottom: 80px;
    background-color: white;
    width: 50px;
    height: 50px;
    box-shadow: 2px 2px 10px rgb(199, 46, 89,0.3);
    border-radius: 40px;
     background-image: url('../../assets/img/add.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
}
</style>
